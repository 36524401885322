import styled from 'styled-components'
import { Link } from 'react-router-dom'
import COLORS from '../ThemeColors'
import media from '../../styles/media'
import logos from '../../assets/images/icons-rrss-white.svg'
import logoMundos from '../../assets/images/mundos.png'
import logoMundosMobile from '../../assets/images/mundos-mobile.png'

export const FooterWrapper = styled.footer`
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    background-color: ${COLORS.black};
    padding: 10px 0;
    ${media.sm`
  padding: 10px 0;
  `}

    .container-copy{

        ${media.md`
      padding: 20px 200px;
      height: 80px;
    `}
    }

    ${media.md`
    flex-direction: row;
    justify-content: space-between;
    height: 252px;
  `}
`

export const ContentCopy = styled.div`
    display: block;
    padding: 0;

    ${media.md`
    padding: 20px 0 0 10%;
  `}

    ${media.xl`
    padding: 20px 0 0 15%;
  `}
`

export const Copyright = styled.div`
    color: ${COLORS.white};
    font-size: 12px;
    text-align: center;
    margin: 30px 0;
    ${media.md`
    margin: 50px 0 30px;
    font-size: 14px;
  `};

    .copyright-text {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        padding-bottom: 24px;
        ${media.sm`
    width: 50%;
    padding-bottom: 0;
    text-align: left;
    `}
    }

    .terms-conditions {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        text-align: center;
        font-size: 12px;
        font-weight: 'bold';
        ${media.md`
      text-align: left;
      font-size: 14px;
    `}
    }
`
export const HelpDesk = styled.div`
    color: ${COLORS.white};
    font-size: 24px;
    text-align: center;
    background-image: url(${logoMundosMobile});
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    padding: 50px 20px;
    height: 750px;
    width: 100%;
    ${media.md`
    background-image: url(${logoMundos});
    height: 650px;
    font-size: 34px;
    padding: 90px 0;
  `}

    label{
        display: block;
        font-size: 18px;
        margin: 10px 0;
        padding: 0px 50px;
    }
`
export const Attendance = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;

    a:first-child {
        margin-bottom: 30px;

        ${media.md`
      margin-right: 30px;
    `}
    }
`

export const SocialNetworks = styled.div`
    display: block;
    text-align: center;
    height: 190px;
    padding: 0px;
    background: ${COLORS.blackBackground};
    color: ${COLORS.white};
    font-size: 18px;
    ${media.md`
    text-align: right;
    padding: 20px 10% 0 0;
    height: 130px;
  `}

    ${media.xl`
    padding: 20px 15% 0 0;
  `}

    .menu-social-network, .menu-social-network li{
        display: inline-block;
        margin: -5px 10px;
        padding: 0;
        label{
            display: block;
            font-size: 16px;
            font-weight: 700;
            margin: 10px 30% 30px;
        }

        a:hover{
            opacity: 1;
        }

        ${media.sm`
      label{
        display: inline-block;
        margin: 10px 30% 30px;
      }
    `}

        ${media.md`
      margin: -5px 0 -5px 15px;

      label{
        margin: -5px 8px -5px 50px;
      }
    `}
    }

    .icon-face{
        background-position: 0 -31px;
    }
    .icon-instagram{
        background-position: 0 -62px;
    }
    .icon-linkedin{
        background-position: 0 -94px;
    }
    .icon-twitter{
        background-position: 0 0;
    }
    .icon-other{
        background-position: 0 -125px;
    }
`
export const ExternalLinkBlank = styled.a`
    color: ${COLORS.white};
    font-size: 16px;
    font-weight: bold;
    margin-left: 5px;
    &:hover {
        text-decoration: underline;
        color: ${COLORS.white};
    }

    ${media.md`
    margin-left: 10%;
  `}

    ${media.lg`
    margin-left: 15%;
  `}

    ${media.xl`
    margin-left: 20%;
  `}
`

export const SocialLink = styled.a`
    color: ${COLORS.gray};
    background-image: url(${logos});
    background-repeat: no-repeat;
    display: block;
    min-width: 34px;
    height: 24px;
    text-indent: -999999rem;
    opacity: 0.7;
    width: 24px;
    
  &:hover {
    text-decoration: none;
    color: ${COLORS.white};
  } 
`

export const ExternalLink = styled.a`
  transition: all 0.5s ease-in-out 0s;
  color: ${COLORS.white};
  background-color: ${COLORS.blueSelect};
  border: solid 2px ${COLORS.blueSelect};
  outline: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  padding: 12px 35px;
  text-align: center;
  height: 50px;
  width: 250px;
  margin-top: 30px;
  border-radius: 40px;

  ${media.sm`
  padding: 8px 20px;
  height: 41px;
  `}

  &:hover {
    color: ${COLORS.white};
    text-decoration: none;
    cursor: pointer;
  }
`
export const CustomLink = styled(Link)`
  display: inline;
  height: 100%;
  width: auto;
  ${media.sm`
  padding: 5px 0;
  `}
  ${media.md`
  padding: 9px 0;
  `}

  &:hover {
    color: ${COLORS.white};
    text-decoration: none;
    cursor: pointer;
  }
`

export const ContentFooter = styled.div`
    display: flex;
    flex-direction: column;
    ${media.md`
      margin-left: 30%;
    `}
`

export const ContentSocial = styled.div`
    display: block;
    margin: 30px 0;
`

export const ContentLogo = styled.div`
    display: block;
    text-align: center;
    margin: 30px 0;
    ${media.md`
      text-align: left;
    `}
`


export const TitleFooter = styled.span`
    margin-top: 130%;
    font-size: 24px;
    ${media.sm`
    margin-top: 50px;
    font-size: 42px;
  `}
    ${media.lg`
    margin-top: 0;
    font-size: 42px;
  `}
`

export const TitleDownload = styled.span`
  margin-top: 20px;
  font-size: 32px;
`

export const TextCallToAction = styled.label`
    color: ${COLORS.white};
    font-size: 16px;
    margin: 10px;
`

export const TextLogo = styled(TextCallToAction)`
    display: block;
`

export const ContentInfo = styled.div`
    display: block;
    margin: 30px 0;

    label{
        font-size: 14px;
    }

    ${media.md`
    margin: 80px 0 30px;
  `}
`

export const CallToAction = styled.a`
    padding-left: 30px;
    color: ${COLORS.white};

    &:hover {
        color: ${COLORS.white};
        text-decoration: none;
        cursor: pointer;
    }
    span{
        font-size: 14px;
        margin: 0 10px;
    }
`
