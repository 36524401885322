import React from 'react'
import Logo from '../../assets/logos/diners-club-white.svg'
import phoneIcon from '../../assets/images/phone-icon.svg'

import {
  FooterWrapper,
  ContentCopy,
  ContentLogo,
  SocialNetworks,
  ContentSocial,
  SocialLink,
  Copyright,
  CustomLink,
  TextLogo,
  CallToAction,
  ContentInfo,
  TextCallToAction,
} from './style'

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
      <>
        <FooterWrapper>
          <ContentCopy>
            <ContentLogo>
              <CustomLink to="/">
                <img
                    style={{ margin: 'auto' }}
                    className="text-center"
                    src={Logo}
                    width={'172px'}
                    alt="Diners Club"
                />
                <TextLogo>Tu mundo sin límites</TextLogo>
              </CustomLink>
            </ContentLogo>
            <Copyright>
              <div className="terms-conditions">
                Copyright ©
                {' '}
                {currentYear}
                {' '}
                Diners Club Ecuador. Derechos reservados.
              </div>
            </Copyright>
          </ContentCopy>
          <SocialNetworks>
            <ContentInfo>
              <TextCallToAction>¿Necesitas ayuda?</TextCallToAction>
              <CallToAction href='tel:(02) 298 1300'>
                <img src={phoneIcon} alt="phone icon" />
                <span>(02) 298 1300</span>
              </CallToAction>
            </ContentInfo>
            <ContentSocial>
              <ul className="menu-social-network">
                <label>SÍGUENOS EN:</label>
                <li>
                  <SocialLink
                      href="https://www.facebook.com/DinersClubEcuador/"
                      target="_blank"
                      className="icon-face"
                  />
                </li>
                <li>
                  <SocialLink
                      href="https://www.instagram.com/dinersclubecu/"
                      target="_blank"
                      className="icon-instagram"
                  />
                </li>
                <li>
                  <SocialLink
                      href="https://www.linkedin.com/company/diners-club-ecuador"
                      target="_blank"
                      className="icon-linkedin"
                  />
                </li>
                <li>
                  <SocialLink
                      href="https://twitter.com/DinersClubEc"
                      target="_blank"
                      className="icon-twitter"
                  />
                </li>
                <li>
                  <SocialLink
                      href="https://www.dinersclub.com.ec/centro-de-soporte/redes-sociales/?utm_source=web&utm_medium=beneficiosdelclub&utm_campaign=beneficiosdelclub-redireccion-na-2024-junio-dinersclub-todos&utm_content=boton-&utm_term=mas-redes"
                      target="_blank"
                      className="icon-other"
                  />
                </li>
              </ul>
            </ContentSocial>
          </SocialNetworks>
        </FooterWrapper>
      </>
  )
}

export default Footer
